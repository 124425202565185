import React from "react"
import { Carousel, Col, Icon, Row, Typography } from "antd"
import Img from "gatsby-image"
import style from "./InfoSlider.module.scss"

class InfoSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: null,
      nav1: null,
      nav2: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.imageSlider,
      nav2: this.textSlider,
    })
    this.setPhoto(0)
  }

  setPhoto = num => {
    this.setState({ currentPhoto: num })
  }

  nextArrow() {
    const wrapperProps = {
      className: style.arrowWrapper,
    }

    const arrowProps = {
      className: style.arrowNext,
      type: "right",
    }

    return (
      <div {...wrapperProps}>
        <Icon {...arrowProps} />
      </div>
    )
  }

  prevArrow() {
    const wrapperProps = {
      className: style.arrowWrapper,
    }

    const arrowProps = {
      className: style.arrowPrev,
      type: "left",
    }

    return (
      <div {...wrapperProps}>
        <Icon {...arrowProps} />
      </div>
    )
  }

  render() {
    const { settings } = this.props

    const imageSliderProps = {
      afterChange: current => this.setPhoto(current),
      arrows: settings.arrows || true,
      asNavFor: this.state.nav2,
      dots: settings.dots || false,
      draggable: true,
      fade: true,
      nextArrow: this.nextArrow(),
      prevArrow: this.prevArrow(),
      ref: slider => (this.imageSlider = slider),
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: settings.speed || 400,
      style: {
        background: settings.background || `rgba(0,0,0,0.0)`,
      },
    }

    const dotsWrapperProps = {
      style: {
        background: `transparent`,
      },
    }

    const dotsProps = {
      className: style.dots,
    }

    const textSliderProps = {
      appendDots: dots => <ul {...dotsWrapperProps}>{dots}</ul>,
      asNavFor: this.state.nav1,
      className: style.textCarousel,
      customPaging: i => <button {...dotsProps}>{i + 1}</button>,
      dots: true,
      dotsClass: `${style.dots}`,
      draggable: false,
      fade: true,
      focusOnSelect: false,
      lazyLoad: false,
      numThumbs: 1,
      ref: slider => (this.textSlider = slider),
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: settings.speed || 400,
      swipeToSlide: false,
    }

    const rowProps = {
      align: `middle`,
      justify: `center`,
      gutter: 24,
      type: `flex`,
    }

    const imageSliderColProps = {
      xs: 24,
      md: 14,
      xl: 15,
      className: style.imageSliderCol,
    }

    const textSliderColProps = {
      xs: 24,
      md: 10,
      xl: 9,
      className: style.textSliderCol,
    }

    return (
      <Row {...rowProps}>
        <Col {...imageSliderColProps}>
          <Carousel {...imageSliderProps}>
            {this.props.images.map((slide, index) => {
              let { image, title } = slide
              let divProps = {
                className: style.imageWrapper,
                key: `${title}-image`,
              }
              let imgProps = {
                alt: title,
                className: style.image,
                fluid: image.fluid,
              }
              return (
                <div key={`${title}-${index}`} {...divProps}>
                  <Img {...imgProps} />
                </div>
              )
            })}
          </Carousel>
        </Col>
        <Col {...textSliderColProps}>
          <Carousel {...textSliderProps}>
            {this.props.text.map((slide, index) => {
              let { description, title } = slide
              let titleProps = {
                className: style.title,
                level: 3,
              }
              let paragraphProps = {
                className: style.paragraph,
              }
              return (
                <div key={`${title}-${index}`}>
                  <Typography.Title {...titleProps}>{title}</Typography.Title>
                  <Typography.Paragraph {...paragraphProps}>
                    {description}
                  </Typography.Paragraph>
                </div>
              )
            })}
          </Carousel>
        </Col>
      </Row>
    )
  }
}

export default InfoSlider
