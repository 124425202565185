import React from "react"
import { Modal } from "antd"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import style from "./LightboxImage.module.scss"

export default class LightboxImage extends React.Component {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    centered: PropTypes.bool,
    destroyOnClose: PropTypes.bool,
    fit: PropTypes.string,
    fluid: PropTypes.object.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lightboxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    alt: null,
    centered: true,
    destroyOnClose: true,
    fit: "contain",
    fluid: null,
    height: `100%`,
    imageWidth: `100%`,
    lightboxHeight: `80vh`,
  }

  constructor(props) {
    super(props)
    this.state = {
      vis: false,
      width: 0,
    }
  }

  componentDidMount() {
    if (typeof window === "undefined") return
    this.setState({ width: this.modalWidth(window.innerWidth) })
    window.addEventListener("resize", () => {
      this.setState({ width: this.modalWidth(window.innerWidth) })
    })
  }

  toggleModal = () => {
    this.setState({
      vis: !this.state.vis,
    })
  }

  modalWidth = width => {
    if (width <= 768) return width * 0.9
    else if (width <= 992) return width * 0.75
    else if (width <= 1200) return width * 0.6
    else return width * 0.45
  }

  render() {
    const {
      alt,
      centered,
      destroyOnClose,
      fit,
      fluid,
      height,
      imageWidth,
      lightboxHeight,
    } = this.props

    return (
      <div className={style.wrapper}>
        <Modal
          centered={centered}
          className={style.modal}
          destroyOnClose={destroyOnClose}
          footer={null}
          onCancel={this.toggleModal}
          visible={this.state.vis}
          width={this.state.width}
        >
          <Img
            alt={alt}
            className={style.modalImage}
            fluid={fluid}
            imgStyle={{ objectFit: fit }}
            style={{ maxHeight: lightboxHeight }}
          />
        </Modal>
        <div
          onClick={this.toggleModal}
          onKeyDown={this.toggleModal}
          role="button"
          tabIndex={0}
        >
          <Img
            alt={alt}
            className={style.image}
            fluid={fluid}
            imgStyle={{ objectFit: fit }}
            style={{
              height: height,
              width: imageWidth,
            }}
          />
        </div>
      </div>
    )
  }
}
