import React from "react"
import { Col, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import Wistia from "../components/Wistia/Wistia"
import InfoSlider from "../components/InfoSlider/InfoSlider"
import LightboxImage from "../components/LightboxImage/LightboxImage"

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    marginBottom: 32,
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: 1360,
  },
}

const titleColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
  style: {
    paddingBottom: 32,
    paddingTop: 32,
  },
}

const sliderRowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const sliderColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

const videoUrl =
  "https://sundoghrv.wistia.com/medias/2oelyln8nh?embedType=iframe&videoFoam=true&videoWidth=640"

const videoRowProps = {
  align: "middle",
  gutter: 36,
  justify: "center",
  type: "flex",
  style: {
    marginLeft: `auto`,
    marginRight: `auto`,
    marginTop: 48,
    maxWidth: 1360,
    paddingTop: 64,
    textAlign: `center`,
  },
}

const videoTextColProps = {
  xs: 22,
  lg: {
    order: 1,
    span: 10,
  },
  xl: {
    order: 1,
    span: 9,
  },
}

const videoColProps = {
  xs: 22,
  lg: {
    order: 2,
    span: 10,
  },
  xl: {
    order: 2,
    span: 9,
  },
}

const sitePlanRowProps = {
  align: "middle",
  gutter: 36,
  justify: "center",
  type: "flex",
  style: {
    background: `white`,
    marginLeft: `auto`,
    marginRight: `auto`,
    marginTop: 64,
    maxWidth: 1360,
  },
}

const sitePlanImgColProps = {
  xs: 22,
  md: 10,
  lg: 9,
  xl: 8,
}

const sitePlanCopyColProps = {
  xs: 22,
  md: 10,
  lg: 9,
  xl: 8,
  style: {
    padding: 32,
    textAlign: `center`,
  },
}

export default ({ data }) => {
  const { nodes } = data.ctf
  const { hero, sitePlan } = data

  const meta = {
    seo: {
      title: "Amenities | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
      desc: `This concept is unique to Clemson, and frankly, it’s rare that you’ll find \
        something of this caliber with these kinds of amenities anywhere.`,
    },
    uri: "/amenities",
  }

  const heroProps = {
    caption: `You've never experienced Clemson quite like this.`,
    contentfulAsset: hero,
  }

  const imageSlides = [],
    textSlides = []
  nodes.forEach(node => {
    imageSlides.push({
      title: node.title,
      image: node.image.localFile.childImageSharp,
    })
    textSlides.push({
      title: node.title,
      description: node.description.description,
    })
  })

  return (
    <Shell>
      <Meta {...meta} />
      <Hero {...heroProps} />
      <BookingBar />
      <Row {...rowProps}>
        <Col {...titleColProps}>
          <SeoHeader
            seo={{ level: 1, content: `Amenities` }}
            human={{ level: 2, content: `Tour the Grounds` }}
          />
          <Typography.Paragraph>
            This concept is unique to Clemson, and frankly, it’s rare that
            you’ll find something of this caliber with these kinds of amenities
            anywhere. Our location on Lake Hartwell and near the University is
            only part of what separates us from other hotels in the Upstate.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row {...sliderRowProps}>
        <Col {...sliderColProps}>
          <InfoSlider settings={{}} images={imageSlides} text={textSlides} />
        </Col>
      </Row>
      <Row {...videoRowProps}>
        <Col {...videoColProps}>
          <Wistia oEmbedUrl={videoUrl} style={{ maxWidth: 550 }} />
        </Col>
        <Col {...videoTextColProps}>
          <Typography.Title level={3} style={{ color: `white`, marginTop: 64 }}>
            Tales of the Tailgate
          </Typography.Title>
          <Typography.Paragraph>
            With plenty of space for a game of catch, gourmet food that's made
            to order, a fully stocked and professionally tended bar, <i>and</i>{" "}
            access to the lake, it's only natural to wonder: Does tailgating get
            any better than this?
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row {...sitePlanRowProps}>
        <Col {...sitePlanImgColProps}>
          <LightboxImage
            alt={sitePlan.description}
            fluid={sitePlan.fluid}
            imageWidth="100%"
          />
        </Col>
        <Col {...sitePlanCopyColProps}>
          <Typography.Title level={3}>Site Plan</Typography.Title>
          <Typography.Paragraph>
            An aerial view of the resort and its amenities, in one simple
            graphic.
          </Typography.Paragraph>
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    ctf: allContentfulHotelAmenity(
      filter: {
        hotel: { elemMatch: { name: { eq: "Lakeside Lodge Clemson" } } }
      }
    ) {
      nodes {
        description {
          description
        }
        image {
          localFile {
            childImageSharp {
              fluid(jpegProgressive: true, jpegQuality: 50, webpQuality: 50) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
              }
            }
          }
        }
        title
      }
    }
    hero: contentfulAsset(contentful_id: { eq: "6qUkh28dLf3O8Kd0jWez59" }) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    sitePlan: contentfulAsset(title: { eq: "LLC - Site Plan" }) {
      description
      fluid(maxWidth: 540) {
        ...fluid_withWebp_tracedSVG
      }
    }
  }
`
